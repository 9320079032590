<template>
  <div>
    <ca-header heading="Einstellungen">
      <template #buttonSpace>
        <button
          class="btn btn-success text-white"
          @click="save()"
        >
          Speichern
        </button>
      </template>
      <template #alert>
        <div
          v-if="saveError"
          class="alert alert-danger"
        >
          Fehler beim Speichern
        </div>
        <div
          v-if="saveSuccess"
          class="alert alert-success"
        >
          Einstellungen gespeichert
        </div>
        <div
          v-if="loading"
          class="alert alert-info"
        >
          <i class="fas fa-spin fa-circle-notch" /> Lade Antragsdaten...
        </div>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ca-card class="mb-3">
            <template #header>
              <div class="row">
                <div class="col">
                  <h4 class="mb-0">
                    Wartungsmodus
                  </h4>
                </div>
                <div class="col-auto">
                  <basic-switch
                    v-model="client.maintenance"
                    :margin="false"
                    label="Wartungsmodus"
                    :right="true"
                    :swtich-state-name="{active: 'aktiv', inactive: 'inaktiv'}"
                  />
                </div>
              </div>
            </template>
            <template #body>
              <basic-textarea
                v-model="client.maintenanceText"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                rows="3"
                :horizontal="true"
                label="Hinweistext im Wartungsmodus"
                placeholder="Hinweistext im Wartungsmodus"
                :align-label-center="false"
              />
            </template>
          </ca-card>

          <ca-card class="mb-3">
            <template #header>
              <div class="row">
                <div class="col">
                  <h4 class="mb-0">
                    Emails als Kopie
                  </h4>
                </div>
                <div class="col-auto">
                  <basic-switch
                    v-model="client.enableCc"
                    :margin="false"
                    label="Email als Kopie"
                    :right="true"
                    :swtich-state-name="{active: 'aktiv', inactive: 'inaktiv'}"
                  />
                </div>
              </div>
            </template>
            <template #body>
              <basic-input
                v-model="client.cc"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Emails als Kopie an"
                placeholder="E-Mail Adresse"
              />
            </template>
          </ca-card>
          <ca-card class="mb-3">
            <template #header>
              <div class="row">
                <div class="col">
                  <h4 class="mb-0">
                    Nachtragsinformation/Differenzberechnung
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <basic-input
                v-model="client.informationEmail"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Emailadresse für Nachtragsdifferenz"
                placeholder="E-Mail Adresse"
              />
            </template>
          </ca-card>
          <!-- <ca-card class="mb-3">
            <template #header>
              <div class="row">
                <div class="col">
                  <h4 class="mb-0">
                    Blacklist Fahrgestellnummern neue Änderungen Export Emails
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <basic-input
                v-model="client.blacklistedChassisExportEmail"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Neue Änderungen Export an"
                placeholder="E-Mail Adresse"
              />
            </template>
          </ca-card> -->
          <ca-card class="mb-3">
            <template #header>
              <div class="row">
                <div class="col">
                  <h4 class="mb-0">
                    Ankündigung
                  </h4>
                </div>
                <div class="col-auto">
                  <basic-switch
                    v-model="client.announcement"
                    :margin="false"
                    :right="true"
                    :swtich-state-name="{active: 'aktiv', inactive: 'inaktiv'}"
                  />
                </div>
              </div>
            </template>
            <template #body>
              <basic-textarea
                v-model="client.announcementText"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                rows="4"
                :horizontal="true"
                label="Ankündigungstext"
                placeholder="Ankündigungstext"
                :align-label-center="false"
              />
            </template>
          </ca-card>

          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Dokumente
              </h4>
            </template>
            <template #body>
              <BasicFileUpload
                v-model="client.erstinformationenFile"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                placeholder="GVC hochladen"
                :horizontal="true"
                accept=".pdf"
                label="Erstinformationen"
                :align-label-center="false"
                @uploaded="setFileId($event, 'erstinformationenFileId')"
                @removed="removeFile('erstinformationenFileId')"
              />
              <hr>
              <BasicFileUpload
                v-model="client.datenschutzinformationenFile"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                placeholder="GVC hochladen"
                :horizontal="true"
                accept=".pdf"
                label="Datenschutzinformationen"
                :align-label-center="false"
                @uploaded="setFileId($event, 'datenschutzinformationenFileId')"
                @removed="removeFile('datenschutzinformationenFileId')"
              />
              <hr>
              <BasicFileUpload
                v-model="client.vertragsunterlagenFile"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                placeholder="GVC hochladen"
                :horizontal="true"
                accept=".pdf"
                label="Vertragsunterlagen"
                :align-label-center="false"
                @uploaded="setFileId($event, 'vertragsunterlagenFileId')"
                @removed="removeFile('vertragsunterlagenFileId')"
              />
              <hr>
              <BasicFileUpload
                v-model="client.selbstbewertungFile"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                placeholder="Selbstbewertungsbogen hochladen"
                :horizontal="true"
                accept=".pdf"
                label="Selbstbewertungsbogen"
                :align-label-center="false"
                @uploaded="setFileId($event, 'selbstbewertungFileId')"
                @removed="removeFile('selbstbewertungFileId')"
              />
            </template>
          </ca-card>

          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Produkt
              </h4>
            </template>
            <template #body>
              <BasicInput
                v-model="client.produktversion"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Produktversion"
              />
            </template>
          </ca-card>

          <ca-card class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Versicherer
              </h4>
            </template>
            <template #body>
              <!-- <BasicInput
                v-model="client.produktversion"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Produktversion"
              /> -->
              <div class="form-row">
                <div
                  class="col-12 col-md-4 d-flex"
                >
                  Hinterlegte Versicherer
                </div>
                <div class="col-12 col-md-8">
                  <Versicherer
                    v-for="(versicherer, index) in versichererList"
                    :key="index"
                    :index="index"
                    :versicherer="versicherer"
                    class="mb-2"
                    @changedVesichererLocal="updateVersichererData($event, index)"
                  />
                  <div class="d-flex w-100">
                    <button
                      class="btn btn-sm btn-link"
                      @click="addVersicherer"
                    >
                      <i class="far fa-plus fa-fw mr-1" />Versicherer hinzufügen
                    </button>
                  </div>
                </div>
              </div>
            </template>
          </ca-card>

          <ca-card class="mb-3">
            <template #header>
              <div class="row">
                <div class="col">
                  <h4 class="mb-0">
                    Zwei-Faktor Authentifizierung
                  </h4>
                </div>
              </div>
            </template>
            <template #body>
              <div class="mb-3">
                <template v-if="twoFactorActive">
                  <i class="fas fa-check-circle text-success" /> Zwei-Faktor Authentifizierung ist aktiv
                </template>
                <template v-else>
                  <i class="fas fa-times-circle text-danger" /> Zwei-Faktor Authentifizierung ist nicht aktiv
                </template>
              </div>
              <TwoFactorItem
                name="Authenticator-App"
                description="Aktivieren um Nutzern die Möglichkeit zu geben, sich mit einer Authenticator-App zu authentifizieren"
                :configured="totpConfigured"
                :user-count="totpUserCount"
                @add="addTwoFactor('TOTP')"
                @reset="resetTwoFactor('TOTP')"
              >
                <template slot="icon">
                  <i class="fad fa-fw fa-lock-alt fa-2x text-primary" />
                </template>
              </TwoFactorItem>
              <hr>
              <TwoFactorItem
                name="U2F Security-Token"
                description="Aktivieren um Nutzern die Möglichkeit zu geben, sich mit einem Security-Key zu authentifizieren"
                :configured="u2fConfigured"
                :user-count="u2fUserCount"
                @add="addTwoFactor('U2F')"
                @reset="resetTwoFactor('U2F')"
              >
                <template slot="icon">
                  <i class="fad fa-fw fa-key fa-2x text-primary" />
                </template>
              </TwoFactorItem>
              <hr>
              <TwoFactorItem
                name="SMS"
                description="Aktivieren um Nutzern die Möglichkeit zu geben, sich per SMS zu authentifizieren"
                :configured="smsConfigured"
                :user-count="smsUserCount"
                @add="addTwoFactor('SMS')"
                @reset="resetTwoFactor('SMS')"
              >
                <template slot="icon">
                  <i class="fad fa-fw fa-sms fa-2x text-primary" />
                </template>
              </TwoFactorItem>

              <basic-select
                v-model="selectedRole"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Rollen"
              >
                <template v-for="(role) in unselectedRoles">
                  <option
                    :key="'role' + role.value"
                    :value="role.value"
                  >
                    <span>{{ role.name }}</span>
                  </option>
                </template>
                <template #append>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="addRole()"
                  >
                    <i class="fas fa-plus" />
                  </button>
                </template>
              </basic-select>

              <div class="form-row justify-content-end">
                <div class="col-12 col-lg-8">
                  <transition-group
                    name="roles"
                    tag="ul"
                    class="list-group list-group-flush"
                  >
                    <li
                      v-for="(role, index) in client.twoFactorRoles"
                      :key="'configRole' + role"
                      class="list-group-item"
                    >
                      <div
                        class="form-row align-items-center"
                      >
                        <div class="col">
                          {{ getRoleName(role) }}
                        </div>
                        <div class="col-auto">
                          <button
                            type="button"
                            class="btn btn-link p-0"
                            @click="removeRole(index)"
                          >
                            <i class="fas fa-trash text-danger" />
                          </button>
                        </div>
                      </div>
                    </li>
                  </transition-group>
                </div>
              </div>
            </template>
          </ca-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header.vue'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea.vue'
import BasicSwitch from '@/components/BaseComponents/BasicSwitch.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import TwoFactorItem from './components/TwoFactorItem.vue'
import CaCard from '@/components/Card.vue'
import Versicherer from './components/Versicherer.vue'

export default {
  components: {
    BasicInput,
    BasicTextarea,
    BasicSwitch,
    CaHeader,
    TwoFactorItem,
    BasicSelect,
    CaCard,
    BasicFileUpload,
    Versicherer
  },
  data () {
    return {
      loading: false,
      loadingError: null,
      client: null,
      savePending: false,
      saveSuccess: false,
      saveError: null,
      selectedRole: null,
      roles: [
        { name: 'Händler ohne GVC', value: 0 },
        { name: 'Händler mit GVC', value: 1 },
        { name: 'Händler mit GVC ohne Benachrichtigung', value: 2 },
        { name: 'Admin', value: 10 }
      ],
      versichererList: []
    }
  },
  computed: {
    totpConfigured () {
      return this.client && !!this.client.twoFactor && !!this.client.twoFactor.TOTP && !!this.client.twoFactor.TOTP.enabled
    },
    u2fConfigured () {
      return this.client && !!this.client.twoFactor && !!this.client.twoFactor.U2F && !!this.client.twoFactor.U2F.enabled
    },
    smsConfigured () {
      return this.client && !!this.client.twoFactor && !!this.client.twoFactor.SMS && !!this.client.twoFactor.SMS.enabled
    },
    twoFactorActive () {
      return this.totpConfigured || this.u2fConfigured || this.smsConfigured
    },
    totpUserCount () {
      return this.$store.getters['users/find']({
        query: {
          $or: [
            { 'twoFactor.TOTP.secret': { $ne: null } }
          ]
        }
      }).total
    },
    u2fUserCount () {
      return this.$store.getters['users/find']({
        query: {
          $or: [
            { 'twoFactor.U2F.publicKey': { $ne: null } },
            { 'twoFactor.U2F.keyHandle': { $ne: null } }
          ]
        }
      }).total
    },
    smsUserCount () {
      return this.$store.getters['users/find']({
        query: {
          $or: [
            { 'twoFactor.SMS.mobileNumber': { $ne: null } },
            { 'twoFactor.SMS.secret': { $ne: null } }
          ]
        }
      }).total
    },
    unselectedRoles () {
      return this.roles.filter(role => !this.client.twoFactorRoles.includes(role.value))
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    setFileId (file, id) {
      this.client[id] = file._id
    },
    removeFile (id) {
      this.client[id] = null
    },
    async fetchData () {
      try {
        this.loading = true
        const clientReq = await this.$store.dispatch('clients/find', {
          query: {
            $limit: 1
          }
        })
        this.client = clientReq.data[0]
        this.fetchVersicherer()
        if (!this.client.twoFactorRoles) this.$set(this.client, 'twoFactorRoles', [])
      } catch (error) {
        this.loadingError = error
        console.error(error)
      } finally {
        this.loading = false
      }
    },
    async save () {
      try {
        this.savePending = true
        await this.client.save()
        this.versichererList.forEach(versicherer => {
          versicherer.save()
        })
        this.saveSuccess = true
      } catch (error) {
        this.saveError = error
        console.error(error)
      } finally {
        this.savePending = false
        this.fetchVersicherer()
      }
    },
    async addTwoFactor (type) {
      if (!this.client.twoFactor) this.$set(this.client, 'twoFactor', {})
      if (!this.client.twoFactor[type]) this.$set(this.client.twoFactor, type, {})
      this.$set(this.client.twoFactor[type], 'enabled', true)

      await this.$store.dispatch('clients/patch', [this.client._id, { [`twoFactor.${type}.enabled`]: true }])
    },
    async resetTwoFactor (type) {
      this.$set(this.client.twoFactor[type], 'enabled', false)
      await this.$store.dispatch('clients/patch', [this.client._id, { [`twoFactor.${type}.enabled`]: false }])
    },
    addRole () {
      if (this.selectedRole !== null) {
        this.client.twoFactorRoles.push(this.selectedRole)
        this.selectedRole = null
      }
    },
    removeRole (index) {
      this.client.twoFactorRoles.splice(index, 1)
    },
    getRoleName (roleValue) {
      const role = this.roles.find(role => role.value === roleValue)
      if (role) return role.name
    },
    async addVersicherer () {
      const newVersicherer = new this.$FeathersVuex.api.Versicherer()
      newVersicherer.name = ''
      newVersicherer.status = false
      newVersicherer.anteil = ''
      this.versichererList.push(newVersicherer)
    },
    async updateVersichererData (event, index) {
      this.$set(this.versichererList, index, event)
    },
    async fetchVersicherer () {
      this.versichererList = []
      const versichererReq = await this.$store.dispatch('versicherer/find', {
        query: {
          $limit: 1000
        }
      })
      this.versichererList = versichererReq.data
    }
  }
}
</script>

<style scoped lang="scss">

.site-header{
  z-index: 1020;
}

</style>
