<template>
  <div
    class="card"
  >
    <div
      class="d-flex justify-content-between card-header bg-white hover-gray-2 cursor-pointer py-3"
      @click="visible = !visible"
    >
      <div
        class="d-flex align-items-center justify-content-between"
      >
        <h6 class="mb-0">
          {{ versicherer.name }}
        </h6>
        <span
          class="badge ml-2"
          :class="{ 'badge-success' : versicherer.status, 'badge-danger' : !versicherer.status }"
        >
          {{ versicherer.status ? "aktiv" : "inaktiv" }}
        </span>
      </div>
      <div>
        <span
          class="btn bg-transparent p-0"
        >
          <i
            class="far fa-fw"
            :class="{'fa-chevron-down': visible, 'fa-chevron-left': !visible}"
          />
        </span>
      </div>
    </div>
    <div
      :class="{'p-0': !visible}"
    >
      <b-collapse
        v-model="visible"
      >
        <div class="card-body">
          <form
            novalidate
            class="from-row"
            @submit.prevent
          >
            <basic-input
              v-model="versichererLocal.name"
              data-test="name"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              placeholder="Name"
              label="Name"
              type="text"
              :margin="false"
              class="mb-3"
            />
            <basic-input
              v-model="versichererLocal.anteil"
              data-test="anteil"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              :horizontal="true"
              placeholder="0"
              label="Anteil in %"
              type="number"
              :margin="false"
              class="mb-3"
            >
              %
            </basic-input>
            <div class="form-row">
              <basic-switch
                v-model="versichererLocal.status"
                :margin="false"
                :right="true"
                :swtich-state-name="{active: 'aktiv', inactive: 'inaktiv'}"
              />
            </div>
          </form>
        </div>
      </b-collapse>
    </div>
  </div>
</template>
<script>
import BasicInput from '@/components/BaseComponents/BasicInput'
import BasicSwitch from '@/components/BaseComponents/BasicSwitch.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  components: {
    BasicInput,
    BasicSwitch
  },
  emit: ['changedVesichererLocal'],
  props: {
    index: {
      type: Number,
      required: true
    },
    versicherer: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      versichererLocal: {},
      visible: false,
      initLoad: true
    }
  },
  watch: {
    versicherer: {
      immediate: true,
      handler: function () {
        if (!this.initLoad) return
        this.versichererLocal = cloneDeep(this.versicherer)
        this.initLoad = false
      }
    },
    versichererLocal: {
      handler: function () {
        this.$emit('changedVesichererLocal', this.versichererLocal)
      },
      deep: true
    }
  }
}
</script>
