<template>
  <div class="mb-3">
    <div class="py-3 pl-3">
      <div class="row align-items-center">
        <div class="col-auto">
          <slot name="icon" />
        </div>
        <div class="col">
          <h5 class="mb-0 d-inline">
            {{ name }}
          </h5>
          <p
            v-if="configured"
            class="d-inline"
          >
            - <span class="font-weight-bold">{{ userCount }} Nutzer</span> verwend{{ userCount !== 1 ? 'en': 'et' }} diese Methode
          </p>
          <p class="mb-0 text-gray-4">
            {{ description }}
          </p>
        </div>
        <div class="col-auto">
          <template v-if="configured">
            <button
              :id="'reset'+name"
              type="button"
              class="btn btn-secondary"
            >
              Deaktivieren
            </button>
            <b-popover
              :ref="'popover'+name"
              :target="'reset'+name"
              placement="left"
              title="Sind Sie sich sicher?"
              triggers="click"
            >
              <p>
                Sind Sie sicher, dass Sie die Methode "{{ name }}" deaktivieren möchten? <br>
                Sie entfernen diese damit für {{ userCount }} Benutzer.
              </p>
              <button
                class="btn btn-transparen text-primary w-100"
                @click.stop="$refs['popover'+name].$emit('close')"
              >
                Abbrechen
              </button>
              <button
                class="btn btn-danger-light text-danger w-100"
                @click.stop="$emit('reset')"
              >
                Deaktivieren
              </button>
            </b-popover>
          </template>
          <template v-if="!configured">
            <button
              class="btn btn-primary"
              @click="$emit('add')"
            >
              Aktivieren
            </button>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'UserTwoFactorItem',
  props: {
    configured: {
      type: Boolean,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    userCount: {
      type: Number,
      required: true
    }
  }
}
</script>

<style>

</style>
